import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import route from '../../../src/common/utils/route'
import { getShortCompany } from '../../../src/common/utils/getCompany'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}

interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingVeranoEs = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Neumáticos de verano" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          El neumático de verano son
          <strong> el tipo de rueda más vendido en España</strong>. Es el
          neumático más común, al que en mayor o menor medida todos estamos
          acostumbrados. Se caracteriza por ofrecer un{' '}
          <strong>
            rendimiento óptimo cuando las temperaturas se sitúan sobre los 18ºC
          </strong>
          .
        </p>
        <p className={styles.main_description}>
          Las temperaturas de la península ibérica en sus zonas costeras, donde
          los inviernos tienden a ser suaves, son el principal motivo para que
          el neumático de verano se encuentre tan extendidos en nuestro país
          (salvo Madrid, 9 de las 10 provincias más pobladas tienen mar). Para
          muchas personas en España lo habitual pasa por cambiar los neumáticos
          únicamente cuando estos están gastados o caducados, lo que nos
          diferencia de otros países de la Unión Europea como Francia o Alemania
          donde los ciudadanos están familiarizados con cambiar las ruedas del
          coche con el cambio de estación.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_home'}
        />

        <section className={styles.section}>
          <h2>
            ¿Cuáles son las características que diferencian a los neumáticos de
            verano?
          </h2>
          <p>
            El neumático de verano es eficiente con temperatura ambiente
            superior a 7ºC alcanzado su
            <strong> máxima eficacia sobre los 25ºC.</strong> Este hecho es el
            que provoca que resulte tan atractivo para ser usado como neumático
            en exclusivo en gran parte de la península.{' '}
            <strong>
              Un neumático de verano puede llegar a desgastarse un 15% más lento
              que un neumático de invierno cuando la temperatura se encuentra
              por encima de los 18ºC.{' '}
            </strong>
            Sin embargo, cuando la temperatura es inferior a los 10ºC sus
            propiedades se ven afectadas causando una drástica caída en
            prácticamente todos sus indicadores de resistencia.
          </p>
          <p>
            Pero, independientemente del incremento del desgaste y el coste
            económico que este puede llegar a suponer, lo realmente relevante
            para el conductor debe ser la seguridad. Es en este punto donde se
            debe tener especial cuidado ya que{' '}
            <strong>
              la temperatura afecta directamente a la capacidad de frenado del
              neumático.{' '}
            </strong>
            Por este motivo, es importante ser consciente de llevar unos
            neumáticos que se ajusten a las necesidades y uso de cada conductor.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            En caso de tener neumáticos de invierno, ¿cuándo debo realizar el
            cambio a neumáticos de verano?
          </h2>
          <p>
            <strong>
              Con la llegada del calor durante los meses de abril y mayo{' '}
            </strong>
            aquellos conductores que hayan realizado un cambio a neumáticos de
            invierno deben realizar el cambio a ruedas de verano. Durante toda
            la temporada de altas temperaturas los neumáticos de veranos se
            adaptarán a la perfección a las características del asfalto. No será
            hasta las primeras caídas de temperatura, para los meses de
            <strong> noviembre y diciembre</strong>, que se deberá realizar de
            nuevo la sustitución a ruedas de invierno.
          </p>
        </section>
        <section className={styles.section}>
          <h2>
            ¿Qué sucede con mis neumáticos de verano cuando no los estoy usando?
          </h2>
          <p>
            En los
            <strong> talleres {getShortCompany()} </strong>
            disponemos de
            <strong> servicio de guarda neumáticos </strong>
            donde nos encargamos de almacenar tus ruedas mientras no las estás
            utilizando. De este modo, cuando baje la temperatura si necesitas
            realizar el cambio a neumáticos de invierno no tendrás de qué
            preocuparte, en {getShortCompany()} nos ocuparemos de guardar tus
            ruedas hasta que vuelvas a necesitarlas.
          </p>
        </section>
        <section className={styles.section}>
          <h2>¿Existen diferentes tipos de neumáticos de verano?</h2>
          <p>
            Por supuesto, el neumático de verano, al ser considerado el
            neumático convencional, se encuentra ampliamente extendido para
            diferentes usos y tipos de vehículo estando disponible en coches{' '}
            <strong>
              4x4, SUV, todoterreno, motos, quad, furgonetas, camiones...
            </strong>
          </p>
        </section>
      </article>
    </div>
  )
}
const LandingVeranoCa = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Pneumàtics d'estiu" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          El pneumàtic d'estiu són{' '}
          <strong>el tipus de roda més venut a Catalunya</strong>. Es
          caracteritza per oferir un{' '}
          <strong>
            rendiment òptim quan les temperatures se situen al voltant dels
            18ºC.
          </strong>
        </p>
        <p className={styles.main_description}>
          Les temperatures tendeixen a ser suaus a la península ibèrica. Aquest
          es el principal motiu pel qual el pneumàtic d'estiu es troba tan estès
          al nostre país. Per a moltes persones a Catalunya l'habitual passa per
          canviar els pneumàtics únicament quan aquests es troben gastats o
          caducats. Aquest fet ens diferencia d'altres països de la Unió Europea
          com França o Alemanya on els ciutadans estan familiaritzats amb
          canviar les rodes del cotxe amb els canvi d'estació.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_home'}
        />
        <section className={styles.section}>
          <h2>
            Quines són les característiques que diferencien els pneumàtics
            d'estiu?
          </h2>
          <p>
            El pneumàtic d'estiu és eficient amb temperatura ambient superior a
            7ºC aconseguit la seva
            <strong> màxima eficàcia sobre els 25ºC.</strong> Aquest fet és el
            que provoca que resulti tan atractiu per a ser l’únic tipus de
            pneumàtic emprat a la península al llarg de tot l’any.{' '}
            <strong>
              Un pneumàtic d'estiu pot arribar a desgastar-se un 15% més lent
              que un pneumàtic d'hivern quan la temperatura es troba per sobre
              dels 18ºC.{' '}
            </strong>
            Tot i això, quan la temperatura és inferior als 10ºC les seves
            propietats es veuen afectades causant una important caiguda en
            pràcticament tots els seus indicadors de resistència.
          </p>
          <p>
            Però, independentment de l'increment del desgast i el cost econòmic
            que aquest pot arribar a suposar, el realment rellevant per al
            conductor ha de ser la seguretat. És en aquest punt on s'ha de tenir
            especial cura ja que{' '}
            <strong>
              la temperatura afecta directament la capacitat de frenada del
              pneumàtic.{' '}
            </strong>
            Per aquest motiu, es important ser conscient de portar uns
            pneumàtics que s’ajustin a les necessitats i usos de cada conductor.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            En cas de tenir pneumàtics d'hivern, quan he de realitzar el canvi a
            pneumàtics d'estiu?
          </h2>
          <p>
            <strong>
              Amb l'arribada de la calor durant els mesos d'abril i maig{' '}
            </strong>
            aquells conductors que hagin realitzat un canvi a pneumàtics
            d'hivern han de realitzar el canvi a rodes d'estiu. Durant tota la
            temporada d'altes temperatures els pneumàtics d'estius s'adaptaran a
            la perfecció a les característiques de l'asfalt. No serà fins les
            primeres caigudes de temperatures, per als
            <strong> mesos de novembre i desembre</strong>, que s'haurà de
            realitzar de nou la substitució a rodes d'hivern.
          </p>
        </section>
        <section className={styles.section}>
          <h2>
            Què passa amb els meus pneumàtics d'estiu quan no els estic fent
            servir?
          </h2>
          <p>
            Als <strong> tallers Rodi </strong> disposem de
            <strong> servei de guarda pneumàtics </strong> on ens encarreguem
            d'emmagatzemar les teves rodes mentre no les utilitzes. D'aquesta
            manera, quan baixi la temperatura si necessites realitzar el canvi a
            pneumàtics d'hivern no tindràs de què preocupar-te, a Rodi ens
            ocuparem de guardar les teves rodes fins que les tornis a
            necessitar.
          </p>
        </section>
        <section className={styles.section}>
          <h2>Existeixen diferents tipus de pneumàtics d'estiu?</h2>
          <p>
            Per descomptat, el pneumàtic d'estiu, al ser considerat el pneumàtic
            convencional, es troba àmpliament estès per a diferents usos i tipus
            de vehicle estant disponible en cotxes{' '}
            <strong>
              4x4, SUV, tot terreny, motos, quad, furgonetes, camions...
            </strong>
          </p>
        </section>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-verano'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'verano',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-verano')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const LandingVerano = ({ ...props }: Props) =>
  locale === 'es' ? (
    <LandingVeranoEs {...props} />
  ) : (
    <LandingVeranoCa {...props} />
  )

export default LandingVerano
